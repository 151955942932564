import React from "react";
import {Props as ViewProps} from "./SwiftUI/View";
import {VStack} from "./SwiftUI/VStack";
import {Text} from "./SwiftUI/Text";
import {FontWeight} from "./SwiftUI/Enums/FontWeight";
import {Spacer} from "./SwiftUI/Spacer";
import {IconV} from "./IconV";
import {Image} from "./SwiftUI/Image";
import {AppStoreButton} from "./AppStoreButton";
import {NavigationLink} from "./SwiftUI/NavigationLink";
import {ConfigConstants} from "../Constants/ConfigConstants";
import {TextAlignment} from "./SwiftUI/Enums/TextAlignment";
import {isMobileOnly} from "react-device-detect";

const icon = require("../Assets/AppIcon.png");
const promo = require("../Assets/promo.jpg");

interface Props extends ViewProps {
}

export const SeeMoreInAppV: React.FC<Props> = (props) => {
    return (
        <VStack spacing={16} {...props} padding={{top: 32, bottom: 64, horizontal: isMobileOnly ? 16 : 32, ...(props.padding as {})}} multilineTextAlignment={TextAlignment.center}>
            <IconV src={icon} frame={{height: 62}} />
            <Text t={"See more in the official AppRaven app"}
                  font={{size: 30, weight: FontWeight.bold}} />
            <Spacer />
            <Text t={"Paid Apps For Free Every Day!"} />
            <Text t={"Best App Deals & Discounts"} />
            <Text t={"10000+ App Reviews"} />
            <Text t={"20000+ App Collections"} />
            <Text t={"And much more!"} />
            <Text lk={`Get AppRaven for free [on the App Store](${ConfigConstants.appStoreLink}).`} />
            <Spacer />
            <Image src={promo} />
            <Spacer />
            <AppStoreButton frame={{width: 150}} />
            <VStack spacing={10}>
                <NavigationLink t={"Read More"} destination={"https://appraven.net"} />
                <NavigationLink fit destination={"mailto:team@appraven.net"} t={"Contact Us"} />
                <NavigationLink fit destination={"mailto:promo@appraven.net?subject=App%20Promotion"} t={"Advertise Your App"} />
            </VStack>
        </VStack>
    )
}
