import React from "react"
import {Props as ViewProps, View} from "../../../Components/SwiftUI/View"
import {VStack} from "../../../Components/SwiftUI/VStack"
import {ForEach} from "../../../Components/SwiftUI/ForEach"
import {AppThumbnailV} from "../../../Components/AppThumbnailV"
import {useQuery} from "@apollo/client"
import {Color} from "../../../Components/SwiftUI/Enums/Color"
import {LoaderV} from "../../../Components/LoaderV";
import {NavigationLink} from "../../../Components/SwiftUI/NavigationLink";
import {useParams} from "react-router-dom";
import {Spacer} from "../../../Components/SwiftUI/Spacer";
import {Text} from "../../../Components/SwiftUI/Text";
import {HorizontalAlignment} from "../../../Components/SwiftUI/Enums/HorizontalAlignment";
import {Fonts} from "../../../Constants/Fonts";
import {GetCollectionByIdQuery, GetCollectionItemsQuery} from "../../../Server API/GraphQL/Collection/Queries";
import {FontWeight} from "../../../Components/SwiftUI/Enums/FontWeight";
import {ScrollView} from "../../../Components/SwiftUI/ScrollView";
import {Axis} from "../../../Components/SwiftUI/Enums/Axis";
import {HStack} from "../../../Components/SwiftUI/HStack";
import {FontDesign} from "../../../Components/SwiftUI/Enums/FontDesign";
import {Toolbox} from "../../../Utilities/Toolbox";
import {Image} from "../../../Components/SwiftUI/Image";
import {ActionButton} from "../../../Components/ActionButton";
import {UserThumbnailV} from "../../Users/User List/UserThumbnailV";
import {SeeMoreInAppV} from "../../../Components/SeeMoreInAppV";
import {Divider} from "../../../Components/SwiftUI/Divider";
import {isMobileOnly} from "react-device-detect";
import {Helmet} from "react-helmet";

const crownSymbol = require("../../../Assets/crown.fill@3x.png")
const thumbsup = require("../../../Assets/hand.thumbsup.fill@3x.png")
const textBubble = require("../../../Assets/text.bubble.fill@3x.png")
const bell = require("../../../Assets/bell.fill@3x.png")

interface Props extends ViewProps {}

export const CollectionV: React.FC<Props> = (props) => {

    const { id } = useParams<{ id: string }>()

    const loader = useQuery(GetCollectionByIdQuery, {
        variables: {
            id: id,
        }
    })

    const itemsLoader = useQuery(GetCollectionItemsQuery, {
        variables: {
            id: id,
            types: ["AUTHOR"],
            page: 0
        }
    })

    function loadData() {
        itemsLoader.fetchMore({ variables: {
            page: itemsLoader.data.collection.items.nextPageable.pageNumber
        }})
    }

    if (!loader.data) {
        return <LoaderV loading={loader.loading} error={loader.error} />;
    }

    const collection = loader.data.collection
    const items: any[] = itemsLoader.data?.collection.items.content ?? []

    function header() {
        const commentCount: number = collection.comments.totalElements
        return (
            <VStack stretch alignment={HorizontalAlignment.leading} padding={{top: isMobileOnly ? 0 : 10, horizontal: 16, bottom: 16}} background={Color.white} cornerRadius={{bottom: 14}} shadow={{radius: 2, y: 4, color: "rgb(0, 0, 0, 0.08)"}}>

                <Text t={collection.title}
                      font={{size: 24, weight: FontWeight.bold}}
                      foregroundStyle={Color.primary} />

                <ScrollView scrollClipDisabled axes={Axis.horizontal} showsIndicators={false}>
                    <HStack font={{size: 17, weight: FontWeight.medium, design: FontDesign.rounded}} foregroundStyle={Color.lightGray} padding={{horizontal: 16}}>
                        <Text t={"Collection"} />
                        <Divider />
                        <Text t={`${collection.appCount} apps`} />
                        <Divider />
                        <Text t={Toolbox.formatDate(new Date(collection.creationDate), true)} />
                        <Divider />
                        <Text t={collection.public ? "Public" : "Private"} />
                        {collection.premiumOnly ? <>
                            <Divider />
                            <Image src={crownSymbol} frame={{height: 15}} offset={{y: -2/3}} />
                        </> : null}
                    </HStack>
                </ScrollView>

                <Divider />

                {collection.description ?
                    <Text lk={collection.description}
                          foregroundStyle={Color.darkGray} />
                    : null}

                <HStack>
                    <ActionButton icon={thumbsup} count={collection.score} fixedSize />
                    {commentCount !== 0 && !collection.commentsRestricted ?
                        <ActionButton icon={textBubble} count={commentCount} fixedSize />
                        : null}
                    <ActionButton icon={bell} count={collection.watchCount} />
                </HStack>

                <UserThumbnailV user={collection.user}
                                padding={{vertical: 8, horizontal: 16}}
                                background={Color.tableBackground}
                                cornerRadius={10} />
            </VStack>
        )
    }

    return (
        <VStack stretch background={Color.groupedBackground}>

            <Helmet>
                <title>{collection.title}</title>
                <meta name="description" content={Toolbox.makeMetaDescription(collection.description)}/>
            </Helmet>

            {header()}

            <Spacer frame={{height: 16}} />

            <VStack stretch spacing={6} alignment={HorizontalAlignment.leading}>

                <Text t={"Apps"}
                      font={Fonts.title}
                      foregroundStyle={Color.gray}
                      padding={{leading: 16}} />

                <VStack stretch spacing={0} background={Color.white} cornerRadius={14}>
                    <ForEach data={items} id={item => item.id} content={item =>
                        <NavigationLink destination={`/app/${item.app.id}`} padding={{horizontal: 12, vertical: 6}}>
                            <AppThumbnailV app={item.app} frame={{height: 62}} />
                        </NavigationLink>
                    } />
                </VStack>

            </VStack>

            <LoaderV loading={itemsLoader.loading} error={itemsLoader.error} />
            {itemsLoader.data?.collection.items.hasNext ? <View onAppear={loadData} /> : null}

            <Spacer frame={{height: 40}} />

            <SeeMoreInAppV background={Color.white} cornerRadius={14} />
        </VStack>
    )
}