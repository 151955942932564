import React from "react";
import {Props as ViewProps, View} from "./SwiftUI/View";
import {Header} from "../Screens/Home/Header";
import {Outlet} from "react-router-dom";
import {VStack} from "./SwiftUI/VStack";
import {Color} from "./SwiftUI/Enums/Color";
import {HStack} from "./SwiftUI/HStack";
import {VerticalAlignment} from "./SwiftUI/Enums/VerticalAlignment";
import {HorizontalAlignment} from "./SwiftUI/Enums/HorizontalAlignment";
import {Helmet} from "react-helmet";
import {TabItem} from "./TabItem";
import {Spacer} from "./SwiftUI/Spacer";
import {isMobileOnly} from "react-device-detect";

import houseSymbol from "../Assets/house.fill.svg"
import bubbleSymbol from "../Assets/bubble.left.and.bubble.right.fill.svg"
import controllerSymbol from "../Assets/gamecontroller.fill.svg"
import flameSymbol from "../Assets/flame.fill.svg"
import {Toolbox} from "../Utilities/Toolbox";

interface Props extends ViewProps {}

export const NavigationView: React.FC<Props> = (props) => {
    return (
        <VStack alignment={HorizontalAlignment.leading} spacing={0} background={isMobileOnly ? Color.white : Color.groupedBackground} padding={{top: isMobileOnly ? 0 : 16, horizontal: isMobileOnly ? 0 : 16, bottom: 100}} style={{minHeight: "100vh"}}>

            <Helmet>
                <title>AppRaven: Apps Gone Free</title>
                <meta name="description" content={Toolbox.defaultMetaDescription}/>
                <meta name="theme-color" content={isMobileOnly ? Color.white : Color.groupedBackground}/>
            </Helmet>

            {isMobileOnly ? <Header padding={{leading: 12, bottom: 8}} /> : null}

            <HStack alignment={VerticalAlignment.top} spacing={0}>
                <Spacer />

                {isMobileOnly ? null : <LeftMenu />}
                {isMobileOnly ? null : <Spacer frame={{width: 16}} />}

                <View style={{width: "100%", alignItems: "stretch", overflow: "hidden"}}
                      frame={{maxWidth: 824}}
                      background={Color.white}
                      cornerRadius={isMobileOnly ? 0 : 14}
                      shadow={{color: "rgb(0, 0, 0, 0.05)", radius: isMobileOnly ? 0 : 8}}
                >
                    <Outlet />
                </View>

                <Spacer />
            </HStack>

            {isMobileOnly ? <TabBar /> : null}

        </VStack>
    )
}

function LeftMenu() {
    return (
        <VStack alignment={HorizontalAlignment.leading} spacing={0}
                background={Color.white}
                cornerRadius={14}
                shadow={{color: "rgb(0, 0, 0, 0.05)", radius: 8}}
                layoutPriority={1}
                style={{position: "sticky", top: 16}}
        >
            <Header padding={{top: 4, leading: 8, trailing: 16}} />
            <TabItem title={"Home"} image={houseSymbol} destination={"/"} compact={false} />
            <TabItem title={"App Reviews"} image={bubbleSymbol} destination={"/reviews?appType=APP"} compact={false} />
            <TabItem title={"Game Reviews"} image={controllerSymbol} destination={"/reviews?appType=GAME"} compact={false} />
            <TabItem title={"Hot Apps"} image={flameSymbol} destination={"/apps/popular?type=apps"} compact={false} />
            <TabItem title={"Hot Games"} image={flameSymbol} destination={"/apps/popular?type=games"} compact={false} />
        </VStack>
    )
}

function TabBar() {
    return (
        <HStack spacing={0}
                frame={{height: 50}}
                background={Color.white}
                shadow={{color: "rgb(0, 0, 0, 0.17)", radius: 4}}
                style={{position: "fixed", bottom: 0, zIndex: 2}}
        >
            <TabItem title={"Home"} image={houseSymbol} destination={"/"} compact={true} />
            <TabItem title={"App Reviews"} image={bubbleSymbol} destination={"/reviews?appType=APP"} compact={true} />
            <TabItem title={"Game Reviews"} image={controllerSymbol} destination={"/reviews?appType=GAME"} compact={true} />
            <TabItem title={"Hot Apps"} image={flameSymbol} destination={"/apps/popular?type=apps"} compact={true} />
            <TabItem title={"Hot Games"} image={flameSymbol} destination={"/apps/popular?type=games"} compact={true} />
        </HStack>
    )
}
